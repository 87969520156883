import { createStore, createHook } from 'react-sweet-state';

// loading = time from request submission to request response
// submitted = successful submission
// undefined = represents initial state, non-deterministic states, or submitted but response with an error
type RequestAccessState = 'loading' | 'submitted' | undefined;

type State = {
	requestAccessState: RequestAccessState;
};

type Action = {
	setRequestAccessState: (
		requestAccessState: RequestAccessState,
	) => ({ setState }: { setState: any }) => void;
};

const Store = createStore<State, Action>({
	initialState: {
		requestAccessState: undefined,
	},
	actions: {
		setRequestAccessState:
			(value) =>
			({ setState }) => {
				setState({ requestAccessState: value });
			},
	},
	name: 'guestRequestUpgradeStore',
});

export const useGuestRequestUpgradeStore = createHook(Store);
