import React, { type FC } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { LoadingButton } from '@atlaskit/button';

export const i18n = defineMessages({
	requestToUpgradeButton: {
		id: 'external-collab-ui.guest-request-to-upgrade-page-tree.request-to-upgrade-button',
		defaultMessage: 'Request full access',
		description:
			'After a guest clicks on the button, a request is submitted to upgrade the guest to a full user',
	},
	requestToUpgradePendingButton: {
		id: 'external-collab-ui.guest-request-to-upgrade-page-tree.request-to-upgrade-pending-button',
		defaultMessage: 'Request pending approval',
		description:
			'This text will appear on a disabled button, indicating that the upgrade request is pending',
	},
});

export type GuestRequestUpgradeButtonProps = {
	appearance?: 'primary' | 'default' | 'link' | undefined;
	requestPending: boolean;
	loading: boolean;
	onClick: (_: React.MouseEvent<HTMLElement, MouseEvent>, analyticsEvent: UIAnalyticsEvent) => void;
};

export const GuestRequestUpgradeButton: FC<GuestRequestUpgradeButtonProps> = ({
	appearance,
	requestPending,
	loading,
	onClick,
}) => (
	<LoadingButton
		appearance={appearance}
		isDisabled={requestPending}
		isLoading={loading}
		onClick={onClick}
	>
		{requestPending ? (
			<FormattedMessage {...i18n.requestToUpgradePendingButton} />
		) : (
			<FormattedMessage {...i18n.requestToUpgradeButton} />
		)}
	</LoadingButton>
);
