import { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { useSessionData } from '@confluence/session-data';
import { BadStatusError, cfetch } from '@confluence/network';

import { useGuestRequestUpgradeStore } from './useGuestRequestUpgradeStore';

export const i18n = defineMessages({
	upgradeRequestNote: {
		id: 'external-collab-ui.guest-request-to-upgrade.upgrade-request-note',
		defaultMessage: 'This guest requested a Confluence license',
		description:
			'Message displayed to the admin within the Admin Hub access request details reminding them that this requester is a guest',
	},
});

export const generateUpgradeRequestBody = (cloudId: string, upgradeRequestNote: string) =>
	JSON.stringify({
		resource: `ari:cloud:confluence::site/${cloudId}`,
		note: upgradeRequestNote,
	});

export const SUBMIT_REQUEST_ENDPOINT = '/gateway/api/invitations/v1/access-requests/request';

export const useSubmitGuestUpgradeRequest: ({
	onCompleted,
}: {
	onCompleted: (error?: Error) => void;
}) => [() => Promise<void>, { loading: boolean; isSubmitted: boolean }] = ({ onCompleted }) => {
	const intl = useIntl();
	const [{ requestAccessState }, { setRequestAccessState }] = useGuestRequestUpgradeStore();
	const { cloudId } = useSessionData();

	const submitGuestUpgradeRequest = useCallback(async () => {
		setRequestAccessState('loading');
		return cfetch(SUBMIT_REQUEST_ENDPOINT, {
			method: 'POST',
			credentials: 'include',
			headers: {
				'Content-Type': 'application/json',
			},
			body: generateUpgradeRequestBody(cloudId, intl.formatMessage(i18n.upgradeRequestNote)),
		})
			.then((response) => {
				if (response.ok && response.status === 200) {
					setRequestAccessState('submitted');
					onCompleted();
				} else {
					setRequestAccessState(undefined);
					throw new BadStatusError(`${response.status}: ${response.statusText}`, response);
				}
			})
			.catch((caughtError) => {
				setRequestAccessState(undefined);
				onCompleted(
					caughtError instanceof Error
						? caughtError
						: new Error('Unknown error occurred during submission'),
				);
			});
	}, [cloudId, intl, onCompleted, setRequestAccessState]);

	return [
		submitGuestUpgradeRequest,
		{
			loading: requestAccessState === 'loading',
			isSubmitted: requestAccessState === 'submitted',
		},
	];
};
